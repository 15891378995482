import { twx } from '@shared/utils/tailwind'

export type VerticalBarColors =
  | 'primary-light'
  | 'tags-red'
  | 'tags-blue'
  | 'pill-warning'
  | 'mute'
  | 'alert'
  | 'tags-happy'

const colorToClassName: Record<VerticalBarColors, string> = {
  'primary-light': 'after:bg-primary-light',
  'tags-red': 'after:bg-tags-red',
  'tags-blue': 'after:bg-tags-blue',
  'pill-warning': 'after:bg-pill-warning',
  mute: 'after:bg-gray-12',
  alert: 'after:bg-alert',
  'tags-happy': 'after:bg-tags-happy',
}
export const VerticalRoundedBar = ({
  color = 'primary-light',
  disabled = false,
  className,
}: {
  color?: VerticalBarColors
  disabled?: boolean
  className?: string
}) => {
  const calculatedColor: string = colorToClassName[color]

  return (
    <div
      aria-hidden={true}
      className={twx(
        "after:content-[' '] after:block after:h-full after:w-[2px] after:rounded-[1px]",
        {
          [calculatedColor]: calculatedColor && !disabled,
          [`after:bg-secondary-07`]: disabled,
        },
        className
      )}
    />
  )
}
