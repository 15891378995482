import { HTMLProps } from 'react'
import { twx } from '@shared/utils/tailwind'

export type Props = HTMLProps<HTMLDivElement> & {
  caretPosition?: 'top' | 'bottom'
}

export function GenericTooltip({
  className = '',
  caretPosition = 'bottom',
  children,
  ...rest
}: Props) {
  const classes = twx(
    'relative bg-form-alert rounded-[6px] text-white text-[14px] leading-[16px] p-[12px] w-fit z-[1]',
    className,
    'after:content-[" "] after:absolute after:w-0 after:h-0 after:border-form-alert after:left-[8px] after:border-l-[16px] after:border-r-[16px] after:border-l-transparent after:border-r-transparent ',
    {
      ['after:top-[-8px] after:border-b-[16px]']: caretPosition === 'top',
      [' after:bottom-[-8px] after:border-t-[16px]']:
        caretPosition === 'bottom',
    }
  )

  if (!children) {
    return null
  }

  return (
    <div className={twx(classes)} {...rest}>
      {children}
    </div>
  )
}
