import { Option } from 'effect'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { getSecurityPolicySettings } from '@shared/api/security_policy_settings'
import { logout } from '@shared/components/Auth/Auth'
import { hasPassedInactivityThreshold } from '@shared/components/Auth/helpers'
import { useUserContext } from '@shared/contexts/UserContext'
import environment from '@shared/environment'
import { isStrictPasswordPolicy } from '@shared/utils/securityPolicySettings'
import { isSuperUser } from '@shared/utils/user'

const STRICT_INACTIVITY_IN_MINUTES = 15
const STRICT_INACTIVITY_IN_MS = STRICT_INACTIVITY_IN_MINUTES * 60 * 1000

function useSecurityBasedInactivity() {
  const { user } = useUserContext()
  const [inactivity, setInactivity] = useState<number>(
    environment.sessionInactivityExpiration
  )
  const [currentInterval, setCurrentInterval] = useState<Option.Option<number>>(
    Option.none
  )

  const match = useRouteMatch<{ orgId?: string }>('*/orgs/:orgId/*')
  const orgId = match?.params.orgId

  useEffect(() => {
    if (orgId) {
      void getSecurityPolicySettings(orgId)
        .then(Option.map(isStrictPasswordPolicy))
        .then(Option.getOrElse(() => false))
        .then(
          (isStrict) =>
            isStrict &&
            !isSuperUser(user) &&
            setInactivity(STRICT_INACTIVITY_IN_MS)
        )
        .catch(() => {
          // no-op, fall back to default
        })
    }
  }, [orgId])

  useEffect(() => {
    if (Option.isSome(currentInterval)) {
      window.clearInterval(currentInterval.value)
    }

    const intervalId = window.setInterval(() => {
      void hasPassedInactivityThreshold(inactivity).then((hasPassed) => {
        if (hasPassed) {
          void logout('/')
        }
      })
    }, environment.refreshTokenInterval)

    setCurrentInterval(Option.some(intervalId))

    return () => {
      if (Option.isSome(currentInterval)) {
        window.clearInterval(currentInterval.value)
      }
    }
  }, [inactivity])
}

export function SecurityBasedInactivity() {
  useSecurityBasedInactivity()

  return null
}
