import { ImageSize } from '@augusthealth/models/com/august/protos/snapshot'
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react'
import { profilePhotoFileUrl } from '@shared/api/urls'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import { getProfileSvgPath } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  person: PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'gender'>
  size?: ImageSize
}

export const PersonImage = ({ person, size, ...rest }: Props) => {
  const [imgSrc, setImgSrc] = useState(() =>
    profilePhotoFileUrl({
      orgId: person.orgId!,
      facilityId: person.facilityId!,
      personId: person.id!,
      imageSize: size ?? ImageSize.IMAGE_SIZE_168X168,
    })
  )

  const [hasError, setHasError] = useState(false)

  const fallbackToSvgPic = useCallback(
    (_ev: SyntheticEvent<HTMLImageElement>) => {
      if (!hasError) {
        setHasError(true)
        setImgSrc(getProfileSvgPath(person))
      }
    },
    [hasError, person]
  )

  return (
    <img
      src={imgSrc}
      onError={fallbackToSvgPic}
      alt={`profile picture for resident id ${person.id}`}
      aria-hidden="true"
      className={tw`h-full w-full`}
      {...rest}
    />
  )
}
