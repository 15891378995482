import { DosageV2 } from '@shared/types/dosage'
import pluralize from '@shared/utils/pluralize'

type MaxDoseDescriptorOptions = {
  excludeMaximumDescriptor?: boolean
}
export const maximumDosesPerDayDescriptor = (
  dosage: DosageV2,
  options: MaxDoseDescriptorOptions = {}
): string | undefined => {
  if (
    dosage.doseAndRate === undefined ||
    dosage.doseAndRate.maxDosePerDay === undefined ||
    dosage.doseAndRate.maxDosePerDay.value === undefined
  ) {
    return undefined
  }

  const value = dosage.doseAndRate.maxDosePerDay.value
  const unit = dosage.doseAndRate.maxDosePerDay.unit
    ? dosage.doseAndRate.maxDosePerDay.unit
        .replace(/^UNIT_OF_TIME_/, '')
        .toLowerCase()
    : 'dose'

  return `${options.excludeMaximumDescriptor ? '' : 'Maximum '}${pluralize(
    unit,
    value,
    true
  )} in 24 hours`
}

export function minimumTimeBetweenDosesDescriptor(
  dosage: DosageV2
): string | undefined {
  if (
    dosage.doseAndRate === undefined ||
    dosage.doseAndRate.minTimeBetweenDose === undefined ||
    dosage.doseAndRate.minTimeBetweenDose.value === undefined
  ) {
    return undefined
  }

  const value = dosage.doseAndRate.minTimeBetweenDose.value
  const unit = dosage.doseAndRate.minTimeBetweenDose.unit
    ? dosage.doseAndRate.minTimeBetweenDose.unit
        .replace(/^UNIT_OF_TIME_/, '')
        .toLowerCase()
    : 'hour'

  return `Minimum ${value} ${pluralize(unit, value)} between doses`
}
