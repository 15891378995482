import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import Icon from '@shared/components/Icon'
import { LinkCard } from '@shared/components/LinkCard'
import { PersonImage } from '@shared/components/PersonImage'
import Pill from '@shared/components/Pill'
import { Bed } from '@shared/types/bed'
import { HumanName } from '@shared/types/human_name'
import { OutOfFacilityDetail, Person } from '@shared/types/person'
import { Room } from '@shared/types/room'
import { getFullName } from '@shared/utils/humanName'
import { formatRoomNumber } from '@shared/utils/person'
import { tw, twx } from '@shared/utils/tailwind'

type PersonToLink = Pick<Person, 'id' | 'gender' | 'orgId' | 'facilityId'> & {
  name: HumanName
  profilePhotoUrl: string
  outOfFacilityDetail?: OutOfFacilityDetail | null
}

interface Props {
  person: PersonToLink
  roomInfo: {
    roomDetails?: Room
    bedDetails?: Bed
  }
  href: string
  cardClassName?: string
  anchorClassName?: string
  children?: React.ReactNode
  headerEnd?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  LinkComponent?: (
    props: LinkProps & React.RefAttributes<HTMLAnchorElement>
  ) => ReactNode
}

export const PersonCard = ({
  person,
  children,
  href,
  headerEnd,
  disabled = false,
  roomInfo,
  cardClassName,
  anchorClassName,
  onClick,
  LinkComponent = Link,
}: Props) => {
  const formattedRoom = formatRoomNumber(
    roomInfo.roomDetails?.name,
    roomInfo.bedDetails?.name
  )

  // Space content inline with resident details in >= md sizes
  const residentPhotoSize = 'size-12'
  const contentMediumWidthLeftSpacing = 'md:ml-12 md:pl-layout-gap'

  return (
    <LinkCard
      LinkComponent={LinkComponent}
      href={href}
      onClick={onClick}
      className={twx(
        'flex w-full flex-col gap-layout-gap',
        'border border-transparent p-layout-gap shadow',
        {
          'border-secondary-10 bg-secondary-11 grayscale hover:bg-secondary-11':
            disabled,
          'hover:border-august-primary hover:bg-gray-13': href,
        },
        cardClassName
      )}
      anchorClassName={twx(
        'focus:outline-button-primary-color',
        anchorClassName
      )}
    >
      <div className={twx('flex w-full flex-row items-start justify-between')}>
        <div
          className={tw`flex flex-row items-center justify-start gap-layout-gap`}
        >
          <div
            className={twx(
              'overflow-hidden rounded-md shadow-photo',
              residentPhotoSize
            )}
          >
            <PersonImage person={person} loading={'lazy'} />
          </div>
          <div
            className={tw`flex flex-col gap-layout-gap-xxs py-layout-gap-xxs`}
          >
            <div
              className={tw`flex gap-layout-gap-xs text-sm font-semibold text-gray-01`}
            >
              {getFullName(person.name)}
              {person.outOfFacilityDetail && (
                <Pill shape="rectangle" className={tw`bg-gray-06`}>
                  Away
                </Pill>
              )}
            </div>
            {formattedRoom.length > 0 && (
              <div
                className={tw`flex items-center gap-layout-gap-xs text-sm font-medium text-gray-07`}
              >
                <Icon name="bed" />
                <span>{formattedRoom}</span>
              </div>
            )}
          </div>
        </div>
        <div className={tw`py-1`}>{headerEnd}</div>
      </div>
      {children && (
        <div className={twx('empty:hidden', contentMediumWidthLeftSpacing)}>
          {children}
        </div>
      )}
    </LinkCard>
  )
}
