import Pill, { PillShape, PillSize } from '@shared/components/Pill'
import { twx } from '@shared/utils/tailwind'

export type Schedule = 'schedule 2' | 'schedule 3' | 'schedule 4' | 'schedule 5'
type MedOrderPillType =
  | 'self-administered'
  | 'narcotic'
  | 'psychotropic'
  | 'treatment'
  | 'prn'
  | 'prn follow-up'
  | 'before meal'
  | 'after meal'
  | 'vital'
  | Schedule

type Props = {
  type: MedOrderPillType
  className?: string
  shape?: PillShape
  size?: PillSize
}
export const MedOrderPill = ({
  type,
  className,
  shape = 'rectangle',
  size,
}: Props) => {
  return (
    <Pill
      shape={shape}
      size={size}
      className={twx(
        'w-fit shrink-0 whitespace-nowrap align-middle font-semibold uppercase',
        {
          'bg-tags-blue text-white': ['vital'].includes(type),
          'bg-tags-purple text-white': ['prn', 'prn follow-up'].includes(type),
          'bg-orange text-white': [
            'narcotic',
            'psychotropic',
            'schedule 2',
            'schedule 3',
            'schedule 4',
            'schedule 5',
          ].includes(type),
          'border-secondary-07 bg-white text-secondary-07': [
            'before meal',
            'after meal',
            'self-administered',
            'treatment',
          ].includes(type),
        },
        className
      )}
    >
      {type}
    </Pill>
  )
}
