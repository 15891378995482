import React, { ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import ButtonLink from '@shared/components/ButtonLink'
import { tw, twx } from '@shared/utils/tailwind'

interface RequiredLabelProps {
  hideDefaultRequired?: boolean
  showError: boolean
  text?: string
}

export function LinkSubLabel({
  onClick,
  text = 'Edit',
}: {
  onClick?: () => void
  text?: string
}) {
  return (
    <ButtonLink
      className={tw`ml-[8px] text-[12px] font-semibold uppercase leading-[12px] text-august-primary`}
      onClick={onClick}
    >
      {text}
    </ButtonLink>
  )
}

export function RequiredLabel({
  showError,
  hideDefaultRequired = false,
  text = 'Required',
}: RequiredLabelProps) {
  if (hideDefaultRequired && !showError) {
    return null
  }

  return (
    <span
      className={twx(
        'ml-[8px] text-[12px] font-semibold uppercase leading-[12px] text-secondary-07',
        {
          'animate-fade-in text-urgent-color': showError,
        }
      )}
    >
      {text}
    </span>
  )
}

export function OptionalLabel() {
  return (
    <span
      className={tw`ml-[8px] text-[12px] font-semibold uppercase leading-[12px] text-secondary-07`}
    >
      Optional
    </span>
  )
}

interface LabelProps {
  children: ReactNode
  htmlFor: string
  uppercase?: boolean
  visuallyHidden?: boolean
  id?: string
  className?: string
}

export function Label({
  children,
  htmlFor,
  className,
  uppercase = true,
  visuallyHidden = false,
  ...rest
}: LabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={twx(
        `mb-0 block text-[14px] font-semibold leading-[18px] text-heading-font-color`,
        className,
        {
          uppercase: uppercase,
          'visually-hidden': visuallyHidden,
        }
      )}
      {...rest}
    >
      {children}
    </label>
  )
}

export function Legend({ children }: { children: ReactNode }) {
  return (
    <legend
      className={tw`mb-0 w-auto text-[14px] font-bold uppercase leading-[18px] text-heading-font-color`}
    >
      {children}
    </legend>
  )
}

export interface LabelAboveInputProps extends React.HTMLProps<HTMLDivElement> {
  subLabel?: SubLabel
  errorMessage?: string
  uppercase?: boolean
  a11yOnly?: boolean
  ctaProps?: {
    text?: string
    onClick?: () => void
  }
  labelClassName?: ClassNameValue
}

export type SubLabel = Required | RequiredWhenError | Optional | Link
type Optional = { tag: 'Optional' }
type Required = { tag: 'Required'; hasError: boolean }
type RequiredWhenError = { tag: 'RequiredWhenError'; hasError: boolean }
type Link = { tag: 'Link'; onClick: () => void }

export const requiredLabel = (hasError: boolean): Required => ({
  tag: 'Required',
  hasError,
})

export const requiredWhenError = (hasError: boolean): RequiredWhenError => ({
  tag: 'RequiredWhenError',
  hasError,
})

export const optionalLabel: Optional = { tag: 'Optional' }

export const linkSubLabel = (onClick: () => void): Link => ({
  tag: 'Link',
  onClick,
})

export function LabelAboveInput({
  subLabel,
  htmlFor,
  errorMessage,
  uppercase = true,
  a11yOnly = false,
  children,
  className = '',
  labelClassName = '',
  ctaProps,
  ...rest
}: LabelAboveInputProps) {
  const tag = subLabel?.tag
  const showRequired = tag === 'Required' || tag === 'RequiredWhenError'
  const hideWithoutError = tag === 'RequiredWhenError'
  const showOptional = tag === 'Optional'
  const showLink = tag === 'Link'

  return (
    <div
      className={twx(
        'mb-[12px] flex w-full items-baseline justify-between',
        { 'visually-hidden': a11yOnly },
        className
      )}
      {...rest}
    >
      <div className={tw`flex items-baseline`}>
        <Label
          uppercase={uppercase}
          htmlFor={htmlFor || ''}
          className={twx(labelClassName)}
        >
          {children}
        </Label>
        {showRequired && (
          <RequiredLabel
            hideDefaultRequired={hideWithoutError}
            showError={subLabel?.hasError || false}
            text={errorMessage}
          />
        )}
        {showOptional && <OptionalLabel />}
        {showLink && (
          <LinkSubLabel text={errorMessage} onClick={subLabel?.onClick} />
        )}
      </div>
      {ctaProps && (
        <div key={ctaProps.text} className={tw`flex items-baseline`}>
          <ButtonLink
            className={tw`ml-[8px] text-[12px] font-semibold`}
            onClick={ctaProps.onClick}
          >
            {ctaProps.text}
          </ButtonLink>
        </div>
      )}
    </div>
  )
}
