import { HumanName } from '@shared/types/human_name'
import { OutOfFacilityDetail } from '@shared/types/person'
import { formatDate } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import Banner from './Banner'

export default function OutOfCommunityBanner({
  person,
  children,
}: {
  person: {
    name: HumanName | HumanName[]
    outOfFacilityDetail?: OutOfFacilityDetail | null
  }
  children?: React.ReactNode
}) {
  return (
    <Banner
      description={{
        icon: 'fa-person-walking-luggage',
        copy: <OutOfCommunityBannerCopy person={person} />,
      }}
    >
      {children ? children : null}
    </Banner>
  )
}

function OutOfCommunityBannerCopy({
  person,
}: {
  person: {
    name: HumanName | HumanName[]
    outOfFacilityDetail?: OutOfFacilityDetail | null
  }
}) {
  return (
    <>
      {getFirstAndLastName(person.name)} has been&nbsp;
      <span className={tw`text-white`}>out of the community</span>&nbsp;
      {person.outOfFacilityDetail?.start?.date && (
        <>
          since&nbsp;
          <span className={tw`text-white`}>
            {formatDate(person.outOfFacilityDetail.start.date)}
          </span>
        </>
      )}
    </>
  )
}
